<template>
  <section class="content-my-account">
    <div class="content-my-account-itens container">
      <div class="content-my-account-itens-header">
        <h1>{{ $t('my_account.my_account') }}</h1>
      </div>
      <div class="content-my-account-itens-body">
        <div class="content-user">
          <div class="content-user-header">
            <h2>
              {{ $t('my_account.welcome') }}
              <span>{{ user.name }}</span>
            </h2>
          </div>
          <div class="content-user-body">
            <router-link tag="a" :to="{ name: 'Register' }" class="links">
              <div class="links-icon">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="file-edit"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    d="M384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-59.6 109.6l-95 95-5.4 48.2c-.7 6.4 4.7 11.9 11.2 11.2l48.2-5.4 95-95c2-2 2-5.2 0-7.2l-46.8-46.8c-2-2-5.2-2-7.2 0zm109.7-30.3l-25.4-25.4c-7.9-7.9-20.7-7.9-28.6 0l-26 26c-2 2-2 5.2 0 7.2l46.8 46.8c2 2 5.2 2 7.2 0l26-26c7.9-7.9 7.9-20.7 0-28.6z"
                    class=""
                  ></path>
                </svg>
              </div>
              <p class="links-text">{{ $t('my_account.edit_data_person') }}</p>
            </router-link>
            <router-link tag="a" :to="{ name: 'MyOrders' }" class="links">
              <div class="links-icon">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="truck"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                >
                  <path
                    d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h16c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"
                    class=""
                  ></path>
                </svg>
              </div>
              <p class="links-text">{{ $t('my_account.my_orders') }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('User', ['user',])
  },
}
</script>

<style lang="scss">
.content-my-account {
  padding: 80px 0px;
  @media (max-width: 991px) {
    padding: 40px 0px;
  }
  &-itens {
    &-header {
      padding-bottom: 10px;
      border-bottom: 1px solid #ebebeb;
      h1 {
        font-size: 35px;
        font-family: "Font Bold";
        color: var(--main-color5);
        margin-bottom: 0;
        text-transform: uppercase;
        @media (max-width: 576px) {
          font-size: 28px;
        }
      }
    }
    &-body {
      padding-top: 45px;
      .content-user {
        &-header {
          text-align: left;
          margin-bottom: 50px;
          h2 {
            font-size: 20px;
            font-family: "Font Light";
            color: var(--main-color5);
            text-transform: uppercase;
            margin-bottom: 0;
            span {
              display: block;
              font-family: "Font Bold";
              color: var(--main-color4);
              font-size: 38px;
              text-transform: none;
              @media (max-width: 576px){
                font-size: 28px;
              }
            }
          }
        }
        &-body {
          display: flex;
          align-items: center;
          padding: 30px 0px;
          @media (max-width: 576px){
            justify-content: center;
            padding: 10px 0px;
          }
          .links {
            margin-right: 50px;
            text-decoration: none;
            &:last-child {
              margin-right: 0;
            }
            &-icon {
              height: 130px;
              width: 130px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: var(--main-color5);
              border-radius: 100%;
              box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
              margin: 0 auto;
              transition: 500ms;
              @media (max-width: 576px) {
                width: 100px;
                height: 100px;
              }
              svg {
                width: 80px;
                height: 80px;
                fill: var(--main-color);
                transition: 500ms;
                @media (max-width: 576px) {
                  width: 50px;
                  height: 50px;
                }
              }
            }
            &-text {
              font-size: 20px;
              font-family: "Font Regular";
              color: var(--main-color4) !important;
              margin-top: 10px;
              margin-bottom: 0;
              text-align: center;
              transition: 500ms;
              @media (max-width: 576px) {
                font-size: 16px;
              }
            }
            &:hover,
            &:focus {
              cursor: pointer;
              .links-icon {
                background: var(--main-color);
                svg {
                  fill: var(--main-color5);
                }
              }
              .links-text {
                opacity: 0.6;
              }
            }
          }
        }
      }
      .btn-continue-shopping {
        display: flex;
        align-items: center;
        justify-content: center;
        background: 0;
        border: 0;
        font-size: 16px;
        font-family: "Font Bold";
        color: var(--main-color4);
        padding: 0;
        text-decoration: none;
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.8;
        }
        img {
          margin-right: 5px;
          @media (max-width: 576px) {
            max-width: 15px;
          }
        }
        svg {
          width: 20px;
          height: 20px;
          fill: var(--main-color4);
          margin-left: 5px;
          @media (max-width: 576px) {
            width: 15px;
            height: 15px;
          }
        }
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
    }
  }
}
</style>